import React from 'react'

function Package() {
  return (

<h1> Our Treatments </h1>



    )
}

export default Package