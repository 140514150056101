


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ReactDOM from 'react-dom';

import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Map from './Components/Map';
// import Safety from './Components/Safety';
import Nearyou from './Components/Nearyou';
import Package from './Components/Package';
import Docter from './Components/Doctor';
import Ophthalmology from './Components/Ophthalmology'

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/contract" element={<Map />} />
          <Route path="/Blog" element={<Nearyou />} />
          <Route path="/Ophthalmology" element={<Ophthalmology/>} />


          {/* <Route path="/Safety" element={<Safety />} /> */}
          <Route path="/Package" element={<Package />} />
          {/* <Route path="/Docter" element={<Docter />} /> */}
          <Route path="/Docter" element={<Docter />} />


        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
